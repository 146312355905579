import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Dayjs from 'dayjs';

// Component imports
import { Section, Text, Button } from '@utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '@component/SEO';
import { JsonLd } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet';

// Styles
import * as postStyles from '../styles/post.module.css';

const Work: React.FC<PageProps> = ({ data }: any) => {
  const post = data.sanityPosts;

  return (
    <>
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          headline: post.title,
          image: post.image.asset.url,
          author: {
            '@type': 'Person',
            name: post.author.name,
          },
          publisher: {
            '@type': 'Organization',
            name: 'Coral Dev',
            logo: {
              '@type': 'ImageObject',
              url:
                'https://img.syn-core.com/coraldev/logos/fullLogo-special.png',
            },
          },
          datePublished: Dayjs(post._createdAt).format('MM/DD/YYYY'),
        }}
      ></JsonLd>

      <SEO
        title={post.title}
        path={`/blog/${post.slug.current}`}
        img={{ url: post.image.asset.url, alt: post.title }}
        description={post.description}
      />

      <article className="bg-gray-700">
        <div className="relative flex flex-col min-h-[75vh]">
          <div className="absolute inset-0">
            <GatsbyImage
              image={post.image.asset.gatsbyImageData}
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="opacity-80 bg-gradient-to-tr from-gray-700 to-coral absolute inset-0 flex flex-col flex-1"></div>
          <Section
            id="hero"
            className="md:min-h-full relative flex flex-col flex-1 max-w-5xl min-h-screen"
          >
            <div className="lg:items-center lg:text-center flex flex-col justify-center flex-1">
              <div className="lg:space-y-12 space-y-8">
                <div className="space-y-2">
                  <Text style="h2" tag="h1" className="md:text-center">
                    {post.title}
                  </Text>
                </div>
                <div className="space-y-2">
                  <div className="md:justify-center md:flex-row md:space-x-2 md:space-y-0 md:items-center flex flex-col space-y-1">
                    <div className="flex space-x-4">
                      <GatsbyImage
                        image={post.author.avatar.asset.gatsbyImageData}
                        alt=""
                        className="lg:h-12 lg:w-12 w-8 h-8 rounded-full"
                      />
                      <Text>{post.author.name}</Text>
                    </div>
                    <span className="md:inline hidden">&mdash;</span>
                    <div>
                      <Text>{post.author.position} @ Coral Dev</Text>
                    </div>
                  </div>
                  <div>
                    <p className="md:text-center text-sm font-medium">
                      {Dayjs(post._createdAt).format('MM/DD/YYYY')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>

        <Section id="post" className="max-w-5xl">
          <div className="prose-white xl:prose-xl 2xl:space-y-20 lg:space-y-16 space-y-12 prose-lg">
            <div className={postStyles.post}>
              <BlockContent
                blocks={post._rawPost}
                projectId="45sqh3wr"
                dataset="production"
              ></BlockContent>
            </div>
            <div className="flex justify-center">
              <Button type="internal" variant="primary" href="/blog">
                Back To Blog
              </Button>
            </div>
          </div>
        </Section>
      </article>
    </>
  );
};

export default Work;

export const query = graphql`
  query Post($slug: String) {
    sanityPosts(slug: { current: { eq: $slug } }) {
      title
      description
      image {
        asset {
          url
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      slug {
        current
      }
      _rawPost
      author {
        name
        position
        avatar {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      _createdAt
    }
  }
`;
